import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Bio.scss'

class Bio extends Component {

  componentDidMount = () => {
    document.body.classList.add('bioBody')
  }

  componentWillUnmount = () => {
    document.body.classList.remove('bioBody')
  }

  render() {
    return (
      <>
        <Container >
          <Row>
            <Col xs='auto' md={{ span: '4', offset: 4 }} className='rounded bioContent' style={{ marginTop: '10px',  padding: '10px' }}>

              <p>Long Gone Blond is a solo project from singer, songwriter, and producer Maggie Maki. She records in her home studio creating mashups of samples &amp; live takes (digital/analog), sometimes with friends/band members Dion Macellari &amp; Andrew Thompson.</p>

              <p>Her latest release 5/13 is Goes Her Own Way (Transglobal Underground Remix). Her album Body was released in late 2018 (she calls it her “cellular memoir”). Quiet Revolution EP (2012). Singles include Under the Hollywood Sign (Transglobal Underground Remix) and It’s a Sunshine Day (cover of the cult classic). Streaming on all platforms, and available on <a href='https://longgoneblond.bandcamp.com/'><b>Bandcamp</b></a>.</p>

              <p>Her previous projects included <a href="https://open.spotify.com/artist/5DcvWABApz03GrJOEbJbQ0?si=0lMDKzA-STe-4Fh0ly9vOg" style={{ color: '#000', textDecoration: 'underline' }}><b>Josephine the Singer</b></a>. These songs were given a slight patina by Grundman Mastering.</p>
            </Col>
          </Row>

        <Row>
          <div style={{ width: '10px', height: '100px', display: 'block' }}>

          </div>
        </Row>
        </Container>

      </>
    )
  }

}

export default Bio
