import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import './Lyrics.scss'

class Lyrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTempleOpen: false,
            isHollywoodOpen: false
        }

        this.handleToggle.bind(this)
    }

    handleToggle = (e, sectionName) => {
        console.log(sectionName)
        console.log(e)
        e.preventDefault()
        this.setState({ [sectionName]: !this.state[sectionName] })
        console.log(this.state[sectionName])
        console.log(this.state[sectionName] === true ? false : true)
    }

    componentDidMount = () => {
        document.body.classList.add('lyricsBody')
    }

    componentWillUnmount = () => {
        document.body.classList.remove('lyricsBody')
    }

    render() {

        const { isTempleOpen, isHollywoodOpen } = this.state
        console.log(isTempleOpen)
        return (
            <div className="Lyrics">
                <section className='section hollywood' key='2'>
                    <Row className='lyricBox justify-content-center'>
                        <Col xs='auto' md={{ span: 'auto', offset: 6 }}>

                            <div id='lyricContentHollywood' className='lyricContent'>
                                <h3>Under the Hollywood Sign</h3>
                                <p>You know there are good things<br />
                                and there are bad things...<br />
                                and they make up everything<br />
                                that happens to you
                                </p>

                                <p>but the thing that I like most<br />
                                is when I look up in the sky<br />
                                and even when my heart is broke<br />
                                I see my Hollywood Sign<br />
                                I'm Under the Hollywood Sign</p>

                                <p>You know there are new flames<br />
                                and there are old flings<br />
                                and they make up everything<br />
                                that we're going through...</p>

                                <p>but the thing that I love most<br />
                                is when I look in your pretty green eyes (blue eyes brown eyes)<br />
                                and I feel [steal] your heart and soul<br />
                                and I know you are mine<br />
                                and we're under the hollywood sign<br />
                                I'm yours and you are mine [I hope that we'll be fine]<br />
                                cuz we're under the Hollywood Sign...</p>

                                <p>Two lips forever<br />
                                why have mine been<br />
                                sealed?</p>

                                <p>You held me<br />
                                I'll hold you under the Hollywood Sign</p>

                                <p>You are my Hollywood, Bollywood, Nollywood sign</p>

                                <p>Lay with me<br />
                                (don't answer that)<br />
                                you played with me under my sign<br />
                                under the Hollywood sign</p>

                                <p>I guess you'll never know...<br />
                                I guess you'll never know...</p>

                                <p>I guess I'll never know... </p>
                                <p>&nbsp;</p>
                            </div>

                        </Col>
                    </Row>
                </section>

                <div className='spacer'></div>
                <section className='section' key='1'>
                    <Row className='lyricBox justify-content-center'>
                        <Col xs="12">
                            <div id='lyricContentTemple' className='lyricContent'>
                                <h3>My Body is a Temple not a Hole</h3>
                                <p>I ONCE WAS FREE<br />
                             I ONCE WAS ME<br />
                             MY BODY WAS A TEMPLE FOR MY SOUL<br />
                                </p>

                                <p>I ONCE WAS THREE<br />
                            I ONCE WAS THE GIRL YOU MADE ME BE<br />
                            MY BODY WAS A TEMPLE FOR MY SOUL</p>

                                <p>BUT NOW YOU'RE KILLIN ME<br />
                            I CAN'T ERASE IT<br />
                            YOU BROKE THE WILL IN ME<br />
                            AND NOW I'M DEAD</p>

                                <p>I RUN<br />
                            BUT I CAN'T GET AWAY<br />
                            HOW COME<br />
                            WHY SHOULD I HAVE TO PAY<br />
                            FOR LOVE<br />
                            I RUN<br />
                            FROM LOVE</p>

                                <p>MY BODY AIN'T A TEMPLE ANYMORE...</p>

                                <p>I ONCE WAS CLEAN<br />
                            I ONCE WAS THE GIRL YOU'D WANNA BE<br />
                            MY BODY WAS A TEMPLE FOR MY SOUL<br />
                            MY BODY'S NOT A TEMPLE ANYMORE<br />
                            MY BODY IS A TEMPLE - NOT A HOLE...</p>
                                <p>&nbsp;</p>
                            </div>

                        </Col>
                    </Row>
                </section>

            </div>
        )
    }

}

export default Lyrics
