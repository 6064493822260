import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import './Listen.scss'
import BodyImage from '../img/lgb-body-bold.jpg'
import LoveLetterImage from '../img/lgb-loveletter-cover.jpg'
import HollywoodImage from '../img/lgb-hollywood-cover.jpg'
import SunshineImage from '../img/lgb-sunshine-cover.jpg'
import HowImage from '../img/lgb-how-cover.jpg'

class Listen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      releases: [
        {
          name: 'Body',
          type: 'album',
          release_date: 'October 25, 2019',
          className: 'body',
          artwork_sm: BodyImage,
          artwork_lg: '',
          tracks: [
            {
              title: 'No Regret',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Sayonara Baby',
              length: '',
              spotify_link: ''
            },
            {
              title: 'My Body Is a Temple Not a Hole',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Prey / Pray (Mia)',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Safe Haven',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Fudad',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Love and Grace (Prey Reprise)',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Road Less Traveled',
              length: '',
              spotify_link: ''
            }
          ],
          stores: [
            {
              name: 'spotify',
              link: ''
            },
            {
              name: 'apple',
              link: ''
            },
            {
              name: 'bandcamp',
              link: ''
            }
          ]
        },
        {
          name: '#LoveLetter',
          type: 'single',
          release_date: 'March 21, 2017',
          className: 'loveletter',
          artwork_sm: LoveLetterImage,
          artwork_lg: '',
          tracks: [
            {
              title: '#LoveLetter',
              length: '',
              spotify_link: ''
            }
          ],
          stores: [
            {
              name: 'spotify',
              link: ''
            },
            {
              name: 'apple',
              link: ''
            },
            {
              name: 'bandcamp',
              link: ''
            }
          ]
        },

        {
          name: 'H.O.W.',
          type: 'single',
          release_date: 'August 14, 2015',
          className: 'how',
          artwork_sm: HowImage,
          artwork_lg: '',
          tracks: [
            {
              title: 'H.O.W.',
              length: '',
              spotify_link: ''
            }
          ],
          stores: [
            {
              name: 'spotify',
              link: ''
            },
            {
              name: 'apple',
              link: ''
            },
            {
              name: 'bandcamp',
              link: ''
            }
          ]
        },


        {
          name: 'It\'s a Sunshine Day',
          type: 'single',
          release_date: 'December 13, 2014',
          className: 'sunshine',
          artwork_sm: SunshineImage,
          artwork_lg: '',
          tracks: [
            {
              title: 'It\'s a Sunshine Day',
              length: '',
              spotify_link: ''
            }
          ],
          stores: [
            {
              name: 'spotify',
              link: ''
            },
            {
              name: 'apple',
              link: ''
            },
            {
              name: 'bandcamp',
              link: ''
            }
          ]
        },

        {
          name: 'Under the Hollywood Sign',
          type: 'single',
          release_date: 'December 13, 2014',
          className: 'hollywood',
          artwork_sm: HollywoodImage,
          artwork_lg: '',
          tracks: [
            {
              title: 'Under the Hollywood Sign',
              length: '',
              spotify_link: ''
            }
          ],
          stores: [
            {
              name: 'spotify',
              link: ''
            },
            {
              name: 'apple',
              link: ''
            },
            {
              name: 'bandcamp',
              link: ''
            }
          ]
        },

        {
          name: 'Quiet Revolution',
          type: 'ep',
          release_date: 'May 2, 2012',
          className: 'quietrevolution',
          artwork_sm: '../img/lgb-hollywood-cover.jpg',
          artwork_lg: '',
          tracks: [
            {
              title: 'Quiet Revolution',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Where? (Lo-Fi Cellphone Edit)',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Mint Julip (Surrender Mix)',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Minnie Aimlessness',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Good Mourning',
              length: '',
              spotify_link: ''
            },
            {
              title: 'Where? (Lo-Fi Movie Mix)',
              length: '',
              spotify_link: ''
            },
          ],
          stores: [
            {
              name: 'spotify',
              link: ''
            },
            {
              name: 'apple',
              link: ''
            },
            {
              name: 'bandcamp',
              link: ''
            }
          ]
        }

      ]
    }

  }

  componentDidMount = () => {
    console.log('fire')
    document.body.classList.add('listenBody')
  }

  componentWillUnmount = () => {
    document.body.classList.remove('listenBody')
  }

  render() {

    const {releases} = this.state

    return (
      <div className="Listen">
         {releases.map((release, i) => (
             <section key={i} className={`section parallax ` + release.className + `-background`}>
             <Row className='album'>
               <Col xs="12" sm="6">
                 <div>
                   <img src={release.artwork_sm} alt='' width="300" height="300" />
                 </div>
               </Col>
               <Col xs="12" sm="6">
                 <h3>{release.name}</h3>
                 <ol>
                   {release.tracks.map(track => (
                     <li>{track.title}</li>
                   ))}
                 </ol>
               </Col>
             </Row>
           </section>
          ))}
      </div>
    )
  }

}

export default Listen
