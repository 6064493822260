import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Navbar, Nav, NavItem, NavLink } from 'react-bootstrap';
import './Header.scss'

class Header extends Component {


  constructor(props) {
    super(props);

    this.state = {
      logout: false,
      target: '',
      isopen: false
    }

    this.toggle = this.toggle.bind(this)
    this.setLink = this.setLink.bind(this)

  }

  toggle() {
    this.setState({
      isopen: !this.state.isopen
    });
  }

  setLink = (e, target) => {
    e.preventDefault()
    this.setState({ target: target })
  }



  render() {
    return (
      <div className="Header">
        {this.state.target !== '' &&
        <>
          <Redirect push to={this.state.target} />
          </>
        }
        <Navbar collapseOnSelect className="header" color="faded" light='true' expand="md">
          <Navbar.Brand href="/"><span className="bannerTitle">
            <svg viewBox="0 -10 350 60"  xmlns="http://www.w3.org/2000/svg">
              <style>
                {`.lgb { 	font-family: 'NeuropolXRg-Regular';
                    font-weight: bold;
                    font-style: normal;
                    font-size: 32px;
                    text-shadow: 1px 0px 4px #8e8d41, 1px 1px 4px #8e8d41, 0px 1px 4px #8e8d41, 
                      -1px 1px 4px #8e8d41, -1px 0px 4px #8e8d41, -1px -1px 4px #8e8d41, 
                    0px -1px 4px #8e8d41, 1px -1px 4px #8e8d41, 0px 0px 4px #8e8d41; fill: #000000; }`
                }
              </style>
              <text x="10" y="30" className="lgb">long gone blond</text>
            </svg>
          </span></Navbar.Brand>
          <Navbar.Toggle right="true" />
          <Navbar.Collapse >
            <Nav className="ml-auto" navbar activeKey={this.state.target}>
              {/*<NavItem>
                <NavLink onClick={(e) => { this.setLink(e, 'listen') }}>Discography</NavLink>
              </NavItem>*/}
              <NavItem>
                <NavLink onClick={(e) => { this.setLink(e, '/') }} href='#' active={this.state.target === '/' ? true : false}>Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={(e) => { this.setLink(e, 'lyrics') }} href='#' active={this.state.target === 'lyrics' ? true : false}>Lyrics</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={(e) => { this.setLink(e, 'bio') }} href='#' active={this.state.target === 'bio' ? true : false}>Bio</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={(e) => { this.setLink(e, 'contact') }} href="#" active={this.state.target === 'contact' ? true : false}>Contact</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="http://store.longgoneblond.com">Store</NavLink>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}


export default Header

