import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router, Route } from 'react-router-dom'
import {createBrowserHistory} from 'history'

import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import './fonts.scss'
import App from './App';
import Contact from './containers/Contact'
import Bio from './containers/Bio'
import Listen from './containers/Listen'
import Lyrics from './containers/Lyrics'
import Header from './components/Header'

import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory()

ReactDOM.render(    
<Router>
      <div className="wrapper">
		<Header />
        <Route exact path="/" component={App} />
        <Route path='/lyrics' component={Lyrics} />
		<Route path="/contact">
            <Contact />
        </Route>
		<Route path="/bio" component={Bio} />
		<Route path="/listen" component={Listen} />
      </div>
    </Router>
, document.getElementById('root'));

serviceWorker.unregister();
