import React, { Component } from 'react'
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios';
import './Contact.scss'

class Contact extends Component {
  constructor(props) {
    super(props)
    this.recaptchaRef = React.createRef()

    this.state = {
      name: "",
      email: "",
      msg: "",
      captcha: "",
      validated: false,
      formState: ''
    }

    this.onFormValueChange = this.onFormValueChange.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount = () => {
    document.body.classList.add('contactBody')
  }

  componentWillUnmount = () => {
    document.body.classList.remove('contactBody')
  }

  onFormValueChange = (val) => {
    const elementName = val.target.name
    const elementValue = val.target.value
    const element = {}
    element[elementName] = elementValue
    this.setState(element)
  }

  onCaptchaChange = (captchaValue) => {
    this.setState({ captcha: captchaValue })
  }

  submitForm = (e) => {
    const form = e.currentTarget;
    const me = this
    console.log(form.checkValidity())
    if (form.checkValidity() === false) {
      me.setState({ validated: false })
      e.preventDefault();
      e.stopPropagation();
    } else {
    }
      e.preventDefault();
      me.setState({ validated: true });
      me.setState({formState: 'sending'})
      const name = this.state.name.trim();
      const email = this.state.email.trim();
      const msg = this.state.msg.trim();
      const captcha = this.recaptchaRef.current.getValue()

        axios.post('https://8kgo133jn9.execute-api.us-west-2.amazonaws.com/v1/contact', {
      //axios.post('https://lgbapi.longgoneblond.com/contact', {
        name: name,
        email: email,
        msg: msg,
        ccode: captcha
      })
        .then(function (response) {
          me.setState({
            name: "",
            email: "",
            msg: "",
            captcha: "",
            validated: false,
            formState: 'sent'
          });
        })
        .catch(function (error) {
          me.setState({formState: 'error'})
          console.log(error);
        });
    
  }



  render() {
    const { validated, formState } = this.state

    return (
      <div className="Contact">
        <Container className="formContainer">
          <Row>
            <Col sm="12">
                <Form noValidate validated={validated} onSubmit={this.submitForm}>
                  <Form.Group controlid="validationName" >
                    <Form.Label htmlFor="contactName" sm={2}>Name</Form.Label>
                    <Col sm={10}>
                      <Form.Control type="text" name="name" id="contactName" value={this.state.name} required placeholder="enter your name" onChange={this.onFormValueChange} />
                      <Form.Control.Feedback type='invalid'>
                        Please enter a name.
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group controlid="validationEmail">
                    <Form.Label htmlFor="contactEmail" sm={2}>Email</Form.Label>
                    <Col sm={10}>
                      <Form.Control type="email" name="email" id="contactEmail" value={this.state.email} required placeholder="email@youraddress.com" onChange={this.onFormValueChange} />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address.
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group controlid="validationMsg">
                    <Form.Label htmlFor="messageText" sm={2}>Message for Maggie:</Form.Label>
                    <Col sm={10}>
                      <Form.Control as="textarea" rows={4} name="msg" id="messageText" value={this.state.msg} required onChange={this.onFormValueChange} />
                      <Form.Control.Feedback type="invalid">
                        Please enter your message.
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Col sm={10} offset={2}>

                      <ReCAPTCHA
                        ref={this.recaptchaRef}
                        sitekey="6LfxKDcUAAAAAJpUyZ2qLOoA5_koy_5E32T7Qe54"
                        onChange={this.onCaptchaChange}
                        className="recaptcha"
                      />
                    </Col>
                  </Form.Group>
                  <Button className="submitButton" type='submit'>Submit</Button>
                  <div style={{width: '100%', height: '50px', display: 'block'}}>
                    {formState === 'sending' &&
                    <><Spinner animation="border" size='sm' role="status">
                    <span className="sr-only">Sending...</span>
                  </Spinner> Sending...</>}
                    {formState === 'sent' &&
                    <>Your message was sent!</>}
                    {formState === 'error' &&
                    <>Oops! An error occurred.</>}
                    </div>
                </Form>
            </Col>
          </Row>
          <Row>
					<div style={{width: '100%', height: '100px', display: 'block'}}>
						
					</div>
				</Row>

        </Container>
      </div>
    )
  }

}

export default Contact
